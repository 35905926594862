export const streetLoadingText: string = (
    "Loading street... if it takes too long, please try again or check that the URL is valid."
)

export const streetsLoadingText: string = (
    "Loading streets... if it takes too long, please try again or check that the URL is valid."
)

export const blogPostLoadingText: string = (
    "Loading post... if it takes too long, please try again or check that the URL is valid."
)

export const blogPostListLoadingText: string = (
    "Loading posts..."
)

export const textPageLoadingText: string = (
    "Loading page... if it takes too long, please try again or check that the URL is valid."
)

export const tagLoadingText: string = (
    "Loading streets for this tag..."
)

export const tagListLoadingText: string = (
    "Loading tags..."
)